<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="编辑支付方式" :visible.sync="EditSealTypeDialogVisible" 
    :close-on-click-modal="false" width="30%" :close="CloseDialog">
        <el-form ref="SealTypeFormRef" :model="SealTypeForm" :rules="SealTypeFormRules" 
        label-width="80px" v-loading="Loading">
            <el-form-item label="名称：" prop="Name">
                <el-input  placeholder="请输入名称" v-model="SealTypeForm.Name" @input="DataChangeTag = true"></el-input>
            </el-form-item>
            <el-form-item label="备注：" prop="Remark">
                <el-input  placeholder="请输入备注" v-model="SealTypeForm.Remark" @input="DataChangeTag = true"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog">取消</el-button>
            <el-button type="primary" @click="EditSealType" :disabled="Loading">确认</el-button>
        </span>
    </el-dialog>    
</template> 

<script>
import { PutSealType } from '@/assets/js/api/sealtypeapi.js'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        sealtypedata:Object,
    },
    computed:{
        EditSealTypeDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            SealTypeForm:{
                ID:0,
                Name:'',
                Remark:'',
                },
            SealTypeFormRules:{
                Name: [
                    { required: true, message: '请输入支付方式名称', trigger: 'blur' }
                ],
            },
            Loading:false,
            DataChangeTag:false,
        }
    },
    watch:{
        EditSealTypeDialogVisible:function (newVisible) {
            if(newVisible){
                this.SealTypeForm.ID = this.sealtypedata.id
                this.SealTypeForm.Name = this.sealtypedata.name
                this.SealTypeForm.Remark = this.sealtypedata.remark
                this.DataChangeTag = false
            }
        }
    },
    methods:{
        CloseDialog(){
            this.EditSealTypeDialogVisible = false
        },
        async EditSealType(){
            if(this.DataChangeTag == false){
                this.$message.warning('数据没有修改，不需要提交');
                return
            }
            this.$refs.SealTypeFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }

            const confirmResult = await this.$confirm('确认操作是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }

            this.Loading = true
            var ret = await PutSealType(this.SealTypeForm)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改活动成功');
                this.$emit("ChangeEditSealType", ret.data)
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.CloseDialog()
        }
    }
}
</script>
<style lang="less" scoped>
    .dialog_div{
        text-align: center;
    }
</style>