<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入活动名称" v-model="QueryInfo.Name" clearable @clear="initQuerySealTypeList"> 
                        <el-button slot="append" icon="el-icon-search" @click="initQuerySealTypeList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="AddSealTypeDialogVisible = true">添加活动</el-button>
                </el-col>
            </el-row>
            <el-table :data="SealTypeList" v-loading="Loading" @row-click="ShowEditDialog"  height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="备注" prop="remark"></el-table-column>
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="DeleteSelectSealType(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="SealTypeTotal">
            </el-pagination>
        </el-card>
        <AddSealTypeDialog :visible.sync="AddSealTypeDialogVisible"
        v-on:ChangeAddSealType='ParentChangeAddSealType($event)'>
        </AddSealTypeDialog>

        <EditSealTypeDialog :visible.sync="EditSealTypeDialogVisible" :sealtypedata="SealTypeData"
        v-on:ChangeEditSealType='ParentChangeEditSealType($event)'>
        </EditSealTypeDialog>
    </div>
</template>
<script>
import { DeleteSealType, GetSealTypeQueryList } from '@/assets/js/api/sealtypeapi.js'
import AddSealTypeDialog from '../Dialog/addsealtypedialog.vue';
import EditSealTypeDialog from '../Dialog/editsealtypedialog.vue';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    components:{
        AddSealTypeDialog,
        EditSealTypeDialog,
    },
    data(){
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                Name:'',
                Start:1,
                Count:SpreadCount[0],
            },
            SealTypeList:[],
            SealTypeTotal:0,
            Loading:false,
            SealTypeData:{},
            AddSealTypeDialogVisible:false,
            EditSealTypeDialogVisible:false,
        }
    },
    created(){
        this.QuerySealTypeList()
    },
    methods:{
        ShowEditDialog(sealtype){
            this.SealTypeData = sealtype
            this.EditSealTypeDialogVisible = true
        },
        initQuerySealTypeList(){
            this.QueryInfo.Start = 1
            this.QueryInfo.Count = this.spreadCount[0]
            this.QuerySealTypeList()
        },
        async QuerySealTypeList(){
            this.Loading = true
            var ret = await GetSealTypeQueryList(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.SealTypeList = ret.data.sealTypes
                this.SealTypeTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
            
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.QuerySealTypeList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.QuerySealTypeList()
        },
        ParentChangeAddSealType(sealtype){
            this.SealTypeList.unshift(sealtype)
            if(this.SealTypeList.length > this.QueryInfo.Count)
            {
                this.SealTypeList.pop()
            }
            this.SealTypeTotal += 1
        },
        ParentChangeEditSealType(sealtype){
            this.SealTypeList.forEach(item=>{
                if(item.id == sealtype.id){
                    item.name = sealtype.name
                    item.remark = sealtype.remark
                }
            })
        },
        async DeleteSelectSealType(sealtype){
            const confirmResult = await this.$confirm('确认删除活动 ' + sealtype.name +' ，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }

            this.Loading = true
            var ret = await DeleteSealType(sealtype.id)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除活动成功');
                this.QuerySealTypeList()
                return
            }
            else
            {
                this.$message.error(ret.message);
            }
        }
    }
}
</script>